<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Lead</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Manage Estimate
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Estimate</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-sm-0 tx-right col-lg-7">
        <button
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="$router.push('/new/estimate')"
        >
          New Estimate
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-9 d-flex mr-0 pr-0">
          <select name="size">
            <option value="1000">1000</option>
          </select>
          <select v-model="filter.branch" class="filter-input ml-2">
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in dataLists2"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <div class="ml-2">
            <label class="filter-lavel mb-0 mr-1">From</label>
            <input
              type="date"
              v-model="filter.from"
              placeholder="From"
              class="filter-input"
            />
            <label class="filter-lavel mb-0 ml-1">To</label>
            <input
              type="date"
              v-model="filter.to"
              placeholder="To"
              class="ml-1 filter-input"
            />
          </div>
        </div>
        <div class="col-md-3 ml-0 pl-0">
          <input type="text" class="float-right" placeholder="Search..." />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="exampleTable">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Estimate ID</th>
              <th class="wd-25p">Lead Name</th>
              <th class="wd-20p">Address</th>
              <th class="wd-10p">Amount</th>
              <th class="wd-10p">Validity</th>
              <th class="wd-10p text-right table-end-item">Action</th>
            </tr>
          </thead>
          <tbody v-for="(estimate, index) in dataSets" :key="index">
            <tr v-if="!removeList.includes(estimate.id)">
              <th scope="row" class="table-start-item">{{ ++index }}</th>
              <td>{{ estimate.date }}</td>
              <td>EST{{ estimate.id }}</td>
              <td>{{ estimate.lead.name }}</td>
              <td>{{ estimate.lead.address }}</td>
              <td>NPR {{ estimate.estimate_total }}</td>
              <td>{{ estimate.validity }}</td>
              <td class="text-right table-end-item">
                <a href="javascript:;" class="mr-3">
                  <i
                    class="fas fa-eye tx-success"
                    @click="view('estimateSlip', estimate.id)"
                  ></i>
                </a>
                <a href="javascript:;" class="mr-3" @click="edit(estimate.id)">
                  <i class="fa fa-edit"></i>
                </a>
                <a href="javascript:;" @click="drop(estimate.id)">
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <EstimateSlip />
  </div>
</template>
<script>
import { Search } from "../../../../mixins/search";
import EstimateSlip from "./EstimateSlip";
import { mapGetters } from "vuex";
export default {
  mixins: [Search],
  components: {
    EstimateSlip,
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
  },
  methods: {
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    edit(id) {
      this.$router.push(`/estimate/${id}`);
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/lead-estimate/${id}`);
    },
    filterByBranch() {
      if (this.dataLists.length != 0) {
        let branch = this.filter.branch;
        let details = this.dataLists.filter(function (data) {
          return data.branch == branch;
        });
        this.dataSets = { ...details };
      }
    },
    filterByTwoDates() {
      this.$store.commit("setApiUrl", "api/lead-estimate/filter/date");
      this.$store.commit("sendData", this.filter);
    },
  },
  mounted() {
    this.$store.commit("getData2", `api/branch`);
    this.$store.commit("getData", `api/lead-estimate`);
  },
};
</script>
<style scoped>
</style>