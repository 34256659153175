<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'estimateSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content bg-white border0">
          <div class="modal-header no-print">
            <div class="row">
              <div class="col-md-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  Estimate Slip - #PRCS{{ details.id }}
                </h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-print"></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-download"></i>
                </div>
                <div
                  class="bill-head-icon mr-1"
                  @click="$store.dispatch('modalClose', 'salesSlip')"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center">
              <p class="font-xxl mb-0">Softechpark Pvt. Ltd.</p>
              <p class="mb-0">
                201 Something St., Something Town, YT 242, Country
                <br />Tel No: 324 445-4544 / youremail@companyname.com
                <br />PAN: 123456789
              </p>
              <hr />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-6 col-lg-8 mg-t-40 mg-sm-t-0">
                <label class="content-label mb-1">Estimate To</label>
                <!--    <h6 class="tx-15 mg-b-10">Juan Dela Cruz</h6> -->
                <p class="mg-b-0">NAME :{{ details.lead.name }}</p>
                <p class="mg-b-0">ADDRESS : {{ details.lead.address }}</p>
                <p class="mg-b-0">PAN : 123456789</p>
              </div>
              <!-- col -->
              <div class="col-sm-6 col-lg-4 mg-t-15">
                <p class="mg-b-0 text-right">
                  Estiamte ID : #PRCS{{ details.id }}
                </p>
                <p class="mg-b-0 text-right">DATE : {{ details.date }}</p>
                <p class="mg-b-0 text-right">
                  Validity : {{ details.validity }}
                </p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->
            <div class="mt-2">
              <table class="table table3">
                <thead class="bg-none">
                  <tr class="bg-none">
                    <th scope class="wd-5p">S.N</th>
                    <th scope class="wd-50p">Particular</th>
                    <th scope class="wd-15p">Qty</th>
                    <th scope class="wd-10p">Rate</th>
                    <th scope class="wd-10p">Discount</th>
                    <th scope class="wd-10p tx-right">Total</th>
                  </tr>
                </thead>
                <tbody class="hover-none">
                  <tr v-for="(item, index) in details.items" :key="index">
                    <th scope="row">{{ ++index }}</th>
                    <td>
                      {{ item.item.name }}
                    </td>
                    <td>{{ item.quantity }} {{ item.item.unit_type.title }}</td>
                    <td>{{ item.rate }}</td>
                    <td>{{ item.discount_amount }}</td>
                    <td class="tx-right">{{ item.total }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div
                class="col-sm-7 col-lg-7 order-2 order-sm-0 mg-t-40 mg-sm-t-0"
              >
                <!-- Amount-in-word -->
                <div class="footer_detail mt-0 mb-2 p-2">
                  <label class="content-label mb-1">Amount In Word</label>
                  <p class="mg-b-0">
                    {{ toWord(details.estimate_total) }}
                  </p>
                </div>
              </div>
              <!-- col -->
              <div
                class="col-sm-4 col-lg-4 order-1 order-sm-0 color-secondary mb-0 mt-0"
              >
                <ul class="list-unstyled lh-6 mb-0">
                  <li class="d-flex justify-content-between">
                    <span>Sub-Total</span>
                    <span>{{ details.grand_total }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>VAT able Amount</span>
                    <span>{{ details.vat_able_amount }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>VAT (13%)</span>
                    <span>{{ details.vat_amount }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <sapn>Total</sapn>
                    <sapn>{{ details.estimate_total }}</sapn>
                  </li>
                </ul>
              </div>
              <!-- col -->
            </div>
            <hr />
            <div class="footer_note">
              <label class="content-label mb-1">Note</label>
              <p class="mb-2">
                {{ details.note }}
              </p>
            </div>
            <!-- <p class="text-right">User: Bhagwan Das Ahrahari</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      details: [],
    };
  },
  computed: {
    ...mapGetters(["dataLists", "dataId", "modalId", "modalMode"]),
  },
  watch: {
    dataId(value) {
      this.details = this.dataLists.find(function (data) {
        return data.id == value;
      });
    },
  },
};
</script>